<template>
  <div class="preview">
    <!--  Header  -->
    <div class="zq-page-title-wrapper d-flex">
        <h3 class="zq-page-title">{{ formData.name }}</h3>
      <IconWithTooltip class="zq--header-tooltip" :text="descriptions.pageTitle"/>
    </div>
    <CTabs class="zq--tabs" :active-tab="activeTab" @update:activeTab="setActiveTab">
      <CTab :title="texts.previewPage.memberTab">
        <MemberDetails />
      </CTab>
      <CTab :title="texts.previewPage.eventsTab">
        <EventsDetails v-if="activeTab === 1" />
      </CTab>
      <CTab :title="texts.previewPage.awardsTab">
        <AwardsDetails v-if="activeTab === 2" />
      </CTab>
      <CTab :title="texts.previewPage.achievementsTab">
        <EntrantsDetails v-if="activeTab === 3" :entityType="'1'" />
      </CTab>
      <CTab :title="texts.previewPage.competitionsTab">
        <EntrantsDetails v-if="activeTab === 4" :entityType="'2'" />
      </CTab>

<!--      <CTab title="Stats">-->
<!--        <StatsDetails></StatsDetails>-->
<!--      </CTab>-->
<!--      <CTab title="Info">-->
<!--        <InfoDetails></InfoDetails>-->
<!--      </CTab>-->
<!--      <CTab title="Messages">-->
<!--        <MessagesDetails></MessagesDetails>-->
<!--      </CTab>-->
    </CTabs>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MemberDetails from '@/views/ziqni/members/MemberDetails';
import EventsDetails from './details/EventsDetails';
import AwardsDetails from './details/AwardsDetails';
import IconWithTooltip from '@/shared/UI/IconWithTooltip';
import { members } from "@/config/descriptions/members.json";
import { membersTexts } from "@/config/pageTexts/members.json";
import EntrantsDetails from '@/shared/components/supportModels/entrants/EntityEntrants';
// import StatsDetails from './details/StatsDetails';
// import InfoDetails from './details/InfoDetails';
// import MessagesDetails from './details/MessagesDetails';

export default {
  name: 'previewMember',
  components: {
    MemberDetails,
    EventsDetails,
    IconWithTooltip,
    AwardsDetails,
    EntrantsDetails
    // MessagesDetails,
    // InfoDetails,
    // StatsDetails,
  },
  props: {
    breadcrumbPath: {
      type: String
    }
  },
  data() {
    return {
      descriptions: {
        ...members.preview
      },
      texts: {
        ...membersTexts
      },
      showCMetaInputs: false,
      formData: {
        name: '',
      },
      activeTab: 0
    };
  },
  computed: {
    ...mapGetters('members', ['member']),
  },
  methods: {
    setActiveTab(val) {
      this.activeTab = val;
    },
  },
  watch: {
    member: {
      deep: true,
      handler: function (val) {
        this.formData = val;
        this.showCMetaInputs = true
      },
    },
  },
};
</script>

<style lang="scss">
.preview {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
