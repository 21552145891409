var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"events-detail content"},[_c('div',{staticClass:"events-detail--actions-header"},[_c('ClSearch',{attrs:{"placeholder":"tableHeader.search"},on:{"searchUpdate":_vm.searchData}}),_c('CButton',{staticClass:"header-icon-buttons mr-3",attrs:{"size":"sm"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleReload.apply(null, arguments)}}},[_c('CIcon',{attrs:{"name":"cil-reload"}})],1)],1),_c('CRow',{staticClass:"mt-1"},[_c('CCol',{attrs:{"col":"12"}},[_c('CCard',{staticClass:"zq-card-table"},[(_vm.ready)?_c('CCardBody',[_c('CDataTable',{ref:"eventTable",staticClass:"zq--table",attrs:{"id":"eventTable","items":_vm.eventsData,"fields":_vm.tableFields,"column-filter":_vm.columnFilter,"items-per-page":_vm.itemsPerPage,"sorter":"","responsive":true,"border":_vm.isDefaultTheme,"hover":_vm.isDefaultTheme,"striped":_vm.isDefaultTheme,"loading":_vm.loading},on:{"pagination-change":_vm.itemsPerPageSelect,"page-change":_vm.paginationChange,"update:sorter-value":_vm.handleSortBy},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('TableLoader')]},proxy:true},{key:"id",fn:function(ref){
var item = ref.item;
return [_c('td',{class:{'column-shadow-start': _vm.isTableScrollbar}},[_c('router-link',{attrs:{"to":{name: "PreviewEvent", params: {id: item.id} }}},[_vm._v(" "+_vm._s(item.id)+" ")])],1)]}},{key:"entityId",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('router-link',{attrs:{"to":{name: "PreviewProduct", params: {id: item.entityId} }}},[_vm._v(" "+_vm._s(item.entityId)+" ")])],1)]}},{key:"memberId",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('router-link',{attrs:{"to":{name: "PreviewMember", params: {id: item.memberId} }}},[_vm._v(" "+_vm._s(item.memberId)+" ")])],1)]}},{key:"created",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.dateFormate(item.created)))])]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"fixed-column-end",attrs:{"data-id":item.id}},[_c('div',{staticClass:"zq--table-actions-wrap"},[_c('div',{staticClass:"competitions-icon-actions zq--actions-table",on:{"click":function($event){return _vm.showDetails(item)}}},[_c('img',{attrs:{"src":require("../../../../assets/icons/search.svg"),"alt":"search"}})])])])]}},{key:"actions-header",fn:function(){return [_c('div',{directives:[{name:"theme-header-actions",rawName:"v-theme-header-actions"}]})]},proxy:true},_vm._l((_vm.columnFilterFields),function(item){return {key:item,fn:function(){return [_c('ColumnFilter',{key:item,attrs:{"field":item.slice(0, -7)},on:{"handleChangeData":_vm.handleColumnFilter}})]},proxy:true}}),{key:"actions-filter",fn:function(){return [_c('div')]},proxy:true}],null,true)}),_c('TableFooter',{attrs:{"page":_vm.page,"pages":_vm.pages,"total":_vm.totalRecords,"itemsPerPage":_vm.itemsPerPage},on:{"updatePagenation":_vm.paginationChange,"updateItemPerPage":_vm.itemsPerPageSelect}})],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }